import Tracker from '@bukalapak/tracker-js';
import axios from 'axios';

if (!global.Tracker) {
  Tracker.config({
    baseUrl: 'https://t.bukalapak.com',
    dev: process.env.NODE_ENV !== 'production',
    platform: 'desktop',
  });
}

const trackerInstance = global.Tracker || Tracker;

trackerInstance.sendPixel = data => {
  const url = trackerInstance.pixel(data);
  return axios.get(url).catch(err => {
    // eslint-disable-next-line
    console.error(`ERROR when sending pixel tracker: ${err.message}`);
  });
};

export default trackerInstance;
