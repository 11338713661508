import wrapSecureClientInterface from 'lib/secure-client';

import experiments from './experiments';
import feeds from './feeds';
import products from './products';
import neo from './neo';

const wrap = (window.API && window.API.wrap) || wrapSecureClientInterface;

if (!wrap) {
  throw new Error('ERROR - Environment not suppoted for API call');
}

const apis = {
  ...experiments,
  ...feeds,
  ...products,
  ...neo,
};

export default wrap(apis, 'xhrCommons');
