export const externalService = (() => {
  let service = process.env.CLIENT_API_URL;

  if (document.querySelector('.js-secure-client')) {
    service = document.querySelector('.js-secure-client').dataset.apiUrl;
  }

  return service;
})();

export default {
  externalService,
};
