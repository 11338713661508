import PopularSameSeller from '../app/PopularSameSeller.vue';

const { Vue } = window;

const initializePopularSameSeller = () => {
  const APP_ID = '#popular_same_seller';

  const app = new Vue({
    name: 'PopularSameSellerRoot',
    render: h => h(PopularSameSeller),
  });

  app.$mount(APP_ID);
};

initializePopularSameSeller();
