import { externalService } from './config';

const baseUrl = '_exclusive/experiments';

export default {
  chooseAlternative(experimentParams) {
    const { experimentName, data } = experimentParams;
    const url = `${externalService}/${baseUrl}/${experimentName}/participants`;

    return this.post(url, 'user', { data });
  },

  scoreMetric(experimentParams) {
    const { experimentName, participant, data } = experimentParams;
    const url = `${externalService}/${baseUrl}/${experimentName}/participants/${participant}/metrics`;

    return this.post(url, 'user', { data });
  },
};
