import { appendParams } from 'shared_js/helpers/url-helper';

import { externalService } from './config';

const transformRecentlyViewedResponse = (response, panelOrder) => ({
  data: [
    {
      order: panelOrder,
      type: 'Recently Viewed',
      title: 'Barang Terakhir Dilihat',
      subtitle: null,
      total: response.data.length,
      products: response.data.map(product => ({
        reference_type: 'product_type_popular',
        product,
        source: '',
        campaign_ids_trigger: '',
        category_id_trigger: '',
        product_id_trigger: '',
        reco_key: '',
        reco_strategy: '',
        reco_type: '',
        seed_order: 0,
        seed_type: '',
        store_id_trigger: '',
      })),
      page_title: 'Barang Terakhir Dilihat',
      label: '',
      url: 'https://www.bukalapak.com/product_history',
      reco_token: '',
    },
  ],
  meta: response.meta,
});

const transformPopularPanelResponse = response => ({
  data: [
    {
      ...response.data,
      ...{
        order: response.data.ordering,
        products: response.data.products.map(product => ({
          reference_type: 'product_type_popular',
          product,
          source: '',
        })),
        type: 'popular-panel',
        subtitles: response.data.subtitles.join(' '),
      },
    },
  ],
  meta: response.meta,
});

export default {
  getMNM(productId, { page }) {
    const params = {
      strategy: 'mnm2',
      limit: 18,
    };
    if (productId) {
      params.product_id = productId;
    }
    if (page) {
      let referrerPage = page;
      if (page === 'confirmation') {
        referrerPage = 'payment_confirmation';
      } else if (page === 'product-detail') {
        referrerPage = 'product_detail';
      }
      params.referrer_page = referrerPage;
    }
    const url = appendParams(`${externalService}/feeds/mnm`, params);
    return this.get(url, 'public');
  },

  getProductRecommendations(productId, page) {
    const params = {
      offsest: 0,
      limit: 18,
    };
    if (productId) params.product_id = productId;

    if (page) {
      let slot;
      switch (page) {
        case page === 'confirmation':
          slot = 'payment_confirmation';
          break;

        case page === 'product-detail':
          slot = 'product_detail';
          break;

        default:
          slot = page;
          break;
      }
      params.slot = slot;
    }

    const url = appendParams(`${externalService}/recommendations/products`, params);
    return this.get(url, 'public');
  },

  getPopularSameSeller(storeId) {
    const params = {
      store_id: storeId,
      limit: 18,
      sort: 'bestselling',
    };
    const url = appendParams(`${externalService}/products`, params);
    return this.get(url, 'public');
  },

  getPopularHomepagePanels(sectionName = '') {
    const url = `${externalService}/products/popular/${sectionName}`;
    return this.get(url, 'public').then(response => {
      if (sectionName) {
        return transformPopularPanelResponse(response);
      }
      return response;
    });
  },

  getHomepageDynamicPanels() {
    const homePanels = 'home';
    const params = {
      platform: 'desktop_web',
    };
    const url = appendParams(`${externalService}/_exclusive/dynamic-panel-sections/${homePanels}`, params);
    return this.get(url, 'public');
  },

  fetchDynamicPanels(dynamicPanelUrl, panelOrder) {
    const url = `${externalService}${dynamicPanelUrl}`;
    if (url.includes('/recently-viewed')) {
      // Recently-viewed data must be transformed
      return this.get(url, 'public').then(response => transformRecentlyViewedResponse(response, panelOrder));
    }

    return this.get(url, 'public').then(response => ({
      data: [
        {
          ...response.data,
          reco_token: response.meta.reco_token,
          ...{
            type: 'Recommendation',
            order: panelOrder,
          },
        },
      ],
      meta: response.meta,
    }));
  },
};
