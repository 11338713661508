<template>
  <div id="reco_same_seller">
    <div v-if="!isLoading && products.length >= 6">
      <product-list-elysium
        v-if="useElysium"
        v-bind="productListElysiumProps"
        @productClick="handleProductClick"
        @seeAllClick="handleSeeAllClick"
      />

      <product-list v-else v-bind="productListProps" />
    </div>
  </div>
</template>

<script>
import api from 'shared_js/api';
import Tracker from 'lib/tracker';

const ProductList = () => import(/* webpackChunkName: 'product-list' */ 'shared_js/components/ProductList');
const ProductListElysium = () =>
  import(/* webpackChunkName: 'product-list-elysium' */ 'shared_js/components/elysium/ProductListV0');

const { STORE } = window;
const PRODUCT_ID = document.getElementById('data-popular').dataset.productId;
const FROM = document.getElementById('data-popular').dataset.from;
const ELYSIUM_VERSION = Number(document.getElementById('data-popular').dataset.elysiumVersion);
const STORE_ID = document.getElementById('data-popular').dataset.storeId;

export default {
  name: 'PopularSameSeller',
  components: {
    ProductList,
    ProductListElysium,
  },
  data() {
    return {
      currentProductId: PRODUCT_ID,
      currentStoreId: STORE_ID,
      contentWindow: 6,
      useElysium: global.APP_SHELL === 'westeros' && ELYSIUM_VERSION === 0,
      isLoading: true,
      loadFail: false,
      products: [],
      scrollOffset: 2,
      title: 'Jualan Lain di Lapak Ini',
      checkScroll: true,
    };
  },

  computed: {
    user() {
      let id;
      let id36;

      // Use momship if available
      if (window.blGlobal && window.blGlobal.user) {
        id = window.blGlobal.user.id || '';
        id36 = window.blGlobal.user.id_36 || '';
      }

      // Use westeros if available
      if (window.USER) {
        id = window.USER.id || '';
        id36 = window.USER.id36 || '';
      }

      return { id, id36 };
    },
    dataTracker() {
      if (this.products.length >= 6) {
        const productsId = [];
        this.products.forEach((el) => {
          productsId.push(el.id);
        });

        const dataTracker = {
          evn: 'view_reco_same_seller',
          platform: 'desktop_web',
          product_ids: JSON.stringify(productsId),
          product_id_trigger: this.currentProductId,
          store_id_trigger: this.currentStoreId,
          strategy: 'popular_same_seller',
          t: Math.round(new Date().getTime() / 1000),
          ui: this.user.id36,
        };
        return dataTracker;
      }
      return null;
    },
    pixelTracker() {
      if (this.dataTracker) {
        const productIds = JSON.parse(this.dataTracker.product_ids);
        const tempIds = [];
        tempIds.push(productIds.slice(0, this.contentWindow));
        for (let i = this.contentWindow; i < productIds.length; i += this.scrollOffset) {
          tempIds.push(productIds.slice(i, i + this.scrollOffset));
        }
        const trackerGroup = tempIds.map((el, index) => ({
          ...this.dataTracker,
          product_id_views: JSON.stringify(el),
          tracked: index === 0,
        }));

        return trackerGroup;
      }
      return null;
    },
    moreUrl() {
      return this.products[0].store.url;
    },
    productListProps() {
      return {
        augmentedProductUrlParams: this.augmentedProductUrlParams,
        contentWindow: this.contentWindow,
        title: this.title,
        handleProductClick: this.handleProductClick,
        handleSeeAllClick: this.handleSeeAllClick,
        handleScroll: this.handleScroll,
        products: this.products,
        seeAllUrl: this.moreUrl,
        scrollerClass: this.scrollerClass,
        scrollOffset: this.scrollOffset,
      };
    },
    productListElysiumProps() {
      return {
        augmentedProductUrlParams: this.augmentedProductUrlParams,
        title: this.title,
        moreUrl: this.moreUrl,
        handleNavigation: this.handleScroll,
        products: this.products,
        variant: FROM,
      };
    },
  },

  watch: {
    products() {
      if (this.products.length >= 6) {
        let active = false;

        const callback = (entries, observer) => {
          entries.forEach((entry) => {
            if (!active && entry.intersectionRatio > 0) {
              this.onVisible();
              active = true;
              observer.unobserve(entry.target);
            }
          });
        };

        const observer = new IntersectionObserver(callback);

        this.$nextTick(() => {
          const el = document.getElementById('reco_same_seller');
          observer.observe(el);
        });
      }
    },
  },
  mounted() {
    this.currentStoreId = document.getElementById('data-popular').dataset.storeId;
    if (this.currentStoreId) {
      this.fetchPopularSameSellers(this.currentStoreId);
    } else if (STORE) {
      const getProduct = STORE.dispatch('pdp/getProduct', { id: this.currentProductId });

      if (getProduct.then) {
        getProduct.then((res) => {
          if (res && res.data) {
            this.currentStoreId = res.data.store.id;
            this.fetchPopularSameSellers(this.currentStoreId);
          }
        });
      }
    }

    if (global.pdpEventBus) {
      ['changeProduct', 'popChangeProduct'].forEach((ev) => {
        global.pdpEventBus.subscribe(ev, this.handleChangeProduct);
      });
    }
  },

  beforeDestroy() {
    if (global.pdpEventBus) {
      ['changeProduct', 'popChangeProduct'].forEach((ev) => {
        global.pdpEventBus.unsubscribe(ev, this.handleChangeProduct);
      });
    }
  },

  methods: {
    onVisible() {
      this.sendPixelTracker(this.pixelTracker[0]);
      this.$set(this.pixelTracker[0], 'tracked', true);
    },
    fetchPopularSameSellers(storeId) {
      this.isLoading = true;
      api
        .getPopularSameSeller(storeId)
        .then((response) => {
          if (response.data.length >= 6) {
            this.products = response.data;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.loadFail = true;
        });
    },

    handleChangeProduct(e) {
      this.currentProductId = e.detail.product.id;
      this.currentStoreId = e.detail.product.store.id;
      this.fetchPopularSameSellers(e.detail.product.store.id);
    },
    handleScroll(index) {
      if (!this.pixelTracker[index].tracked) {
        this.sendPixelTracker(this.pixelTracker[index]);
        this.$set(this.pixelTracker[index], 'tracked', true);
      }
      if (this.checkScroll) {
        api.scoreMetric({
          experimentName: 'PNR-3410_Popular_Same_Seller_Dweb',
          participant: this.user.id,
          data: {
            metric: 'hit_next_popular',
            value: 1,
          },
        });
        this.checkScroll = false;
      }
    },
    sendPixelTracker(pixelTracker) {
      const data = Object.assign({}, pixelTracker);
      delete data.tracked;
      Tracker.sendPixel(data);
    },
    augmentedProductUrlParams() {
      const params = {};

      params.from = 'product-detail';
      params.section = 'pss';

      return params;
    },
    handleProductClick(prod) {
      if (this.dataTracker) {
        const dataTracker = JSON.parse(JSON.stringify(this.dataTracker));
        if (FROM === 'product-detail') {
          dataTracker.referrer = 'product_detail-recommendation-pss';
          dataTracker.source = 'product_detail';
        }
        dataTracker.section = 'popular_same_seller';
        dataTracker.product_id = prod.id;
        delete dataTracker.product_id_views;
        dataTracker.evn = 'view_product';
        dataTracker.t = Math.round(new Date().getTime() / 1000);
        Tracker.event(dataTracker);
      }
      api.scoreMetric({
        experimentName: 'PNR-3410_Popular_Same_Seller_Dweb',
        participant: this.user.id,
        data: {
          metric: 'hit_product_popular',
          value: 1,
        },
      });

      if (FROM === 'product-detail') {
        // PDF Prambanan Experiment
        api.scoreMetric({
          experimentName: 'PDF-915_DWEB_pdp_revamp_prambanan',
          participant: this.user.id,
          data: {
            metric: 'click_jualan_lain_lapak_ini',
            value: 1,
          },
        });
        // End Of PDF Prambanan Experiment
      }
    },
    handleSeeAllClick() {
      api.scoreMetric({
        experimentName: 'PNR-3410_Popular_Same_Seller_Dweb',
        participant: this.user.id,
        data: {
          metric: 'hit_see_all',
          value: 1,
        },
      });
    },
  },
};
</script>
