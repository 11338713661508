const baseUrl = '/products';

export default {
  async getProduct(productId) {
    let result;
    try {
      const { STORE = {} } = global;
      if (STORE.state && STORE.state.pdp && STORE.state.pdp.product) {
        // Fetching product data from global pdp store registered by elysium
        if (global.STORE.state.pdp) {
          result = { data: STORE.state.pdp.product };
        }
      } else {
        const url = `${baseUrl}/${productId}`;

        result = await this.get(url, 'public');
      }
    } catch (err) {
      /** */
    }

    return result;
  },
  getSimilar(productId, limit) {
    const url = `${baseUrl}/${productId}/similar`;
    return this.get(url, 'public', { limit });
  },
};
