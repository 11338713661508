import { stringify, parseUrl } from 'query-string';

export function appendParams(url, params) {
  const urlParts = url.split('#');
  const hash = urlParts[1] ? `#${urlParts[1]}` : '';
  const { url: baseUrl, query: oldParams } = parseUrl(url);
  return `${baseUrl}?${stringify({ ...oldParams, ...params })}${hash}`;
}

export function removeAllParams(url) {
  return parseUrl(url).url;
}

export function extractParams(url) {
  return parseUrl(url).query;
}
