import secureClient from '@bukalapak/secure-client';

if (global.blGlobal && global.document.querySelector('.js-secure-client')) {
  const { blGlobal } = global;
  const $authForm = global.document.querySelector('.js-secure-client');

  const atmInstance = new secureClient.AccessTokenManager({
    userId: blGlobal.user.id,
    env: blGlobal.application.env,
    authEndpoint: $authForm.getAttribute('action'),
    appId: $authForm.querySelector('input[name=application_id]').value,
    authToken: $authForm.querySelector('input[name=authenticity_token]').value,
  });

  secureClient.wrapSecureClientInterface.setup(atmInstance, blGlobal.application.api_url);
}

export default secureClient.wrapSecureClientInterface;
