import { externalService } from './config';

export default {
  retrieveTogglesStatus(ids) {
    const data = {
      id: ids,
      platform: 'bl_web',
    };

    return this.post(`${externalService}/_exclusive/neo/toggles`, 'public', { data });
  },
  retrieveConfigs(data) {
    return this.post(`${externalService}/_exclusive/neo/configs`, 'public', { data });
  },
};
